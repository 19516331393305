import gsap from 'gsap';
import { AnimatedSprite, Assets, Container, Spritesheet } from 'pixi.js';
import { assets } from '@puzzles/core/asset-utility';
import { distance, Vec2 } from '@puzzles/core/math/vector';
import { wait } from '@puzzles/core/async/awaitable';
import { Rescalable } from '@puzzles/core/pixi/display-scaler';

import tutorialHandData from './assets/hand.json';
import handSpriteSheet from './assets/hand.png?url';

@assets('TutorialPointerView', {
  handSpriteSheet: handSpriteSheet,
})
export class TutorialPointerView extends Container implements Rescalable {
  private handIdle!: AnimatedSprite;
  private handDown!: AnimatedSprite;
  private isPointerDown: boolean = false;

  public moveSpeed = 15;
  private scaleFactor = 1;

  constructor() {
    super();
    this.interactive = false;
    this.init();
  }

  private async init() {
    const spritesheet = new Spritesheet(Assets.get('handSpriteSheet'), tutorialHandData);
    await spritesheet.parse();

    this.handDown = new AnimatedSprite(spritesheet.animations.down);
    this.handDown.animationSpeed = 1 / 8;
    this.handDown.loop = false;
    this.addChild(this.handDown);

    this.handIdle = new AnimatedSprite(spritesheet.animations.up);
    this.handIdle.animationSpeed = 1 / 6;
    this.handIdle.loop = false;
    this.addChild(this.handIdle);

    this.alpha = 0;
    this.pivot.set(40, 60);
    this.scale.set(0.5);
  }

  rescale(scaleFactor: number): void {
    this.scaleFactor = scaleFactor;
    this.scale.set(Math.max(scaleFactor * 0.5, 0.4));
  }

  public async pointerDown() {
    if (this.isPointerDown) return;

    gsap.killTweensOf(this);
    this.isPointerDown = true;
    this.handIdle.visible = false;
    this.handDown.visible = true;

    if (!this.isPointerDown) return;
    this.handDown.gotoAndStop(0);
    gsap.to(this, { alpha: 1, duration: 0.3 });
    await wait(200);

    if (!this.isPointerDown) return;
    this.handDown.play();
    await wait(900);
  }

  public async pointerUp() {
    if (!this.isPointerDown) return;

    gsap.killTweensOf(this);
    this.isPointerDown = false;
    this.handDown.visible = false;
    this.handIdle.visible = true;

    this.handIdle.gotoAndStop(0);
    this.handIdle.play();

    if (this.isPointerDown) return;
    await wait(200);
    if (this.isPointerDown) return;
    await gsap.to(this, { alpha: 0, duration: 0.5 });
  }

  public async click() {
    await this.pointerDown();
    this.pointerUp();
  }

  public async moveTo(endPos: Vec2, autoPointerUp = true) {
    if (!this.isPointerDown) return;

    let duration = (0.1 / this.scaleFactor / this.moveSpeed) * distance(this.position, endPos);
    await gsap.to(this, { x: endPos.x, y: endPos.y, duration: duration, ease: 'none' });

    if (!this.isPointerDown) return;
    if (autoPointerUp) {
      await wait(200);
      if (!this.isPointerDown) return;
      this.pointerUp();
    }
  }
}
